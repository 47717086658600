<template>
  <!-- 作品 -->
  <div class="kj_works">
    <div
      class="current-nav centerText"
      v-if="Object.keys(zpData).length != 0"
      :style="{
        background: `url(${zpData.worksImg}) no-repeat`
      }"
    ></div>
    <div class="all_no_data" v-else>
      <i class="el-icon-loading"></i>
      <p>数据正在加载</p>
    </div>
    <div class="kj_works_nav" v-if="!currentId">
      <div class="nav_head">
        <h2>{{ zpData.msgTitle }}</h2>
        <h4 v-html="zpData.msgText"></h4>
      </div>
      <div class="nav_box">
        <div class="nav_box_head">
          <h2 class="head_left">{{ zpData.msgWork }}</h2>
          <div class="head_list">
            <a
              v-for="(item, idx) in zpHeadList"
              :key="idx"
              :href="'#/kj_works?currentIdx=' + item.id"
              :class="[active == item.id ? 'xz_active' : '']"
              @click="switchData(item, idx)"
              >{{ item.name }}</a
            >
          </div>
        </div>
        <ul class="nav_box_list">
          <li v-for="(item, idx) in allzpList" :key="idx">
            <a
              :href="
                '#/kj_works?currentIdx=' + active + '&currentId=' + item.id
              "
            >
              <img v-if="item.site_images" :src="item.site_images" alt="" />
              <i
                class="el-icon-loading lazy centerText"
                style="font-size: 30px"
                v-else
              ></i>
              <div class="item_mask">
                <div class="item_mask_con">
                  <h2>{{ item.title }}</h2>
                  <h4>{{ item.subtitle }}</h4>
                </div>
              </div>
            </a>
          </li>
        </ul>
        <div class="all-pagination">
          <el-pagination
            prev-text="上一页"
            next-text="下一页"
            @current-change="handleCurrentChange"
            :current-page.sync="tabPag.page"
            background
            :page-size="6"
            layout="prev, pager, next,total"
            :total="tabPag.total"
            v-if="tabPag.total > 6"
          >
          </el-pagination>
          <span class="no_meet_conditions" v-else>暂无更多数据</span>
        </div>
      </div>
    </div>
    <div class="kj_works_nav2" v-else style="text-align: center;">
      <div class="works_nav2">
        <div class="works_nav2_left">
          <span>当前位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/kj_index' }"
              >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/kj_works' }"
              ><span @click="currentId = ''">作品</span></el-breadcrumb-item
            >
            <el-breadcrumb-item>{{ zpxqData.type_name }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="works_nav2_right">
          <span>{{ zpxqData.type_name }}</span>
        </div>
      </div>
      <div
        class="container_img"
        v-html="zpxqData.maincontent"
        v-if="zpxqData.maincontent"
      ></div>
      <div class="container_nodata" v-else>暂无数据</div>
    </div>
  </div>
</template>

<script>
import {
  getPageMsg,
  getZzCaseList,
  getCaseDetail,
  getCaseTypes
} from '@/api/api'
export default {
  components: {},
  data () {
    return {
      zpData: {},
      zpHeadList: {},
      allzpList: [],
      dataList: [],
      active: '',
      tabPag: {
        page: 1,
        total: 0
      },
      currentId: '',
      zpxqData: {},
      form: {
        page: 1,
        pagesize: 9,
        type_id: '',
        project: ''
      }
    }
  },
  watch: {
    $route: {
      // 监听query参数变化
      handler () {
        if (this.$route.query.currentIdx) {
          this.active = this.$route.query.currentIdx
          this.getData()
        } else {
          this.active = ''
        }
        if (this.$route.query.currentId) {
          this.currentId = this.$route.query.currentId
          this.getData()
        } else {
          this.currentId = ''
        }
      },
      deep: true
    }
  },
  methods: {
    getData () {
      this.form.project = sessionStorage.getItem(this.changeData() + 'project')
      if (this.$route.query.currentIdx) {
        this.active = this.$route.query.currentIdx
        this.form.type_id = this.active
        this.getDataList(this.form)
      }
      if (this.$route.query.currentId) {
        this.currentId = this.$route.query.currentId
        this.toDetails({ id: this.currentId })
      } else {
        this.currentId = ''
      }
      let id = sessionStorage.getItem(this.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          this.zpData = res.data.data.worksMsg
        }
      })
      this.getTypeList()
      this.getDataList(this.form)
    },
    getTypeList () {
      getCaseTypes(this.form.project).then(res => {
        if (res.data.code == 1) {
          res.data.data.unshift({
            name: 'ALL',
            id: ''
          })
          this.zpHeadList = res.data.data
        }
      })
    },
    getDataList (e) {
      getZzCaseList(e).then(res => {
        if (res.data.code == 1) {
          this.allzpList = res.data.data.list
          this.tabPag.total = res.data.data.total
        }
      })
    },
    switchData (e, i) {
      this.active = e.id
      this.form.type_id = e.id
      this.currentId = e.id
      this.getDataList(this.form)
    },
    toDetails (e) {
      this.currentId = e.id
      getCaseDetail(e.id, this.form.project).then(res => {
        if (res.data.code == 1) {
          this.zpxqData = res.data.data
        }
      })
    },
    handleCurrentChange (val) {
      this.form.page = val
      this.getDataList(this.form)
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.kj_works {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 14px;
  .current-nav {
    background-size: 100% 100% !important;
  }
  .kj_works_nav,
  .kj_works_nav2 {
    margin: 50px auto;
    width: 95%;
    .nav_head {
      color: #5f6061;
      h2 {
        letter-spacing: 5px;
        font-size: 30px;
        color: #2c2c2c;
        margin-bottom: 20px;
      }
      h4 {
        line-height: 2;
        letter-spacing: 3px;
      }
    }
    .nav_box {
      .nav_box_head {
        height: 50px;
        position: relative;
        display: flex;
        justify-content: space-between;
        line-height: 50px;
        margin: 10px 0;
        .head_left {
          letter-spacing: 5px;
          font-size: 26px;
          color: #5f6061;
        }
        .head_list {
          display: flex;
          a {
            color: #888889;
            margin-right: 25px;
          }
          a:hover {
            text-decoration: underline;
            color: #000;
          }
          .xz_active {
            text-decoration: underline;
            color: #000;
            font-size: 18px;
          }
        }
      }
      .nav_box_list {
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        li {
          width: calc(33.33% - 10px);
          box-sizing: border-box;
          margin: 5px;
          overflow: hidden;
          margin-top: 5px;
          height: 240px;
          position: relative;
          display: flex;
          justify-content: center;
          img {
            transform: scale(1);
            width: 100%;
            height: auto;
            min-height: 100%;
            transition: 0.4s;
          }
          .lazy {
            width: 100%;
            height: 100%;
          }
          .item_mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            transition: all 0.4s ease-in-out;
            opacity: 0;
            z-index: 1;
            .item_mask_con {
              position: absolute;
              width: 80%;
              left: 10%;
              bottom: 40px;
              z-index: 3;
              h2,
              h4 {
                color: white;
                line-height: 1;
              }
              h2 {
                font-size: 24px;
                margin-bottom: 20px;
              }
              h4 {
                font-size: 12px;
              }
            }
          }
        }
        li:hover {
          img {
            transform: scale(1.1);
          }
          .item_mask {
            opacity: 1;
          }
        }
      }
    }
    .works_nav2 {
      padding-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .works_nav2_left {
        display: flex;
        color: #333;
        font-size: 14px;
      }
      .works_nav2_right {
        span {
          display: inline-block;
          padding: 15px 50px;
          color: #fff;
          font-size: 16px;
          background-color: #e62022;
        }
      }
    }
    .container_img {
      img {
        max-width: 86%;
      }
    }
    .container_nodata {
      height: 500px;
      line-height: 20;
    }
  }
  .no_data {
    height: 400px;
  }
}
</style>
